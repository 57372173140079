<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col text-white">
        <p>Voulez-vous vraiment {{ this.action === 'lock-provider' ? 'verouiller' : 'dévérouiller' }} le fournisseur :
          <b>{{ provider.account.user.name }}</b> ?</p>
        <base-button @click="lock" type="warning">OUI</base-button>
        <base-button type="primary" @click="goBack">NON</base-button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
             role="alert">
          <strong>Oups! </strong> {{ error }}
          <button type="button" class="close"
                  @click="nullError"
                  data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <base-alert v-if="info.toString().length !== 0" type="primary" dismissible>
          <strong>Terminé !</strong> {{ info }}
        </base-alert>
      </div>
    </div>
  </base-header>
</template>

<script>

import jwt from 'jsonwebtoken'
import {Utils} from "@/utils/utils";

const USER = '28146997'
export default {
  name: "ProviderLock",
  data: () => ({
    key: '',
    api: '',
    provider: null,
    account: '',
    error: '',
    info: '',
    action: 'lock-provider'
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    async generateToken() {
      this.token = jwt.sign({account: USER, type: 'ADMIN'}, this.key, {expiresIn: '2m'})
    },
    goBack() {
      this.$router.push('/providers')
    },
    async lock() {
      await this.generateToken()
      fetch(`${this.api}/${this.action}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'accept': 'keep-alive',
          'access-token': this.token,
          'employee': USER
        },
        body: JSON.stringify({
          "provider": this.provider.account.accountNumber,
          "employee": USER,
        })
      }).then(res => res.json()).then(res => {
        if (res.code === 420) {
          this.error = res.messages[0]['values'][0]
        } else {
          if (res.success === true) {
            this.$router.push('/providers')
          } else {
            this.error = res
          }
        }
      }).catch(err => console.error(err))

    },
    async fetchProviders() {
      await this.generateToken()
      fetch(`${this.api}/providers/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': USER
        }
      }).then(res => res.json()).then(res => {
        if (res.length === 0) this.$router.back()
        this.provider = res[0]
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    if (this.$route.params.provider === undefined || this.$route.params.provider === null)
      this.$router.back()
    if (this.$route.params.action !== undefined && this.$route.params.action !== null)
      if (this.$route.params.action === 'unlock')
        this.action = 'unlock-provider'
    this.account = this.$route.params.provider
    this.provider = this.$route.params.provider
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.fetchProviders()
  }
}
</script>

<style scoped>

</style>